<template>
  <div v-if="student" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="student">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('firstname')"
            label-for="firstname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="student.firstname" type="text" :placeholder="$t('firstname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('lastname')"
            label-for="lastname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="student.lastname" type="text" :placeholder="$t('lastname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('studentNr')"
            label-for="studentNr"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="student.studentNr" type="text" :placeholder="$t('studentNr')" />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('users') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('users')"
            label-for="activeState"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              class="multiselect-users"
              v-model="student.users"
              :options="filteredUsers"
              track-by="number"
              v-on:search-change="onFilterUsers"
              v-on:select="onSelectUser"
              v-on:remove="onSelectUser"
              :filter="true"
              :internal-search="false"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              :deselectLabel="$t('deselectLabel')"
              selectLabel=""
              selectedLabel=""
              :placeholder="'Benutzer ' + $t('select') + '/suchen'"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="tag" slot-scope="{ option }">
                <div>{{ option.firstname }} {{ option.lastname }} - {{ option.email }}</div>
              </template>
              <template slot="noResult">
                <div>{{ $t('no_results') }}</div>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="student"
            modelEditRoute="StudentEditPage"
            modelListRoute="Students"
            modelRouteParamName="studentNumber"
            :updateCallback="updateStudent"
            :createCallback="createStudent"
            :deleteCallback="deleteStudent"
            :fetchPaginatedCallback="fetchStudents"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'StudentEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    studentNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters(['getStudent', 'getStudentByNumber', 'getEnumValues', 'getUsers']),
    buttons() {
      return {
        showDeleteButton: this.student && this.student.number !== '_new' ? true : false,
      };
    },
    student() {
      return this.getStudentByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('student'), route: { name: 'Students' } },
        { name: this.student.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchStudents',
      'fetchStudentByNumber',
      'updateStudent',
      'createStudent',
      'deleteStudent',
      'fetchEnumValues',
      'initStudent',
      'fetchUsers',
    ]),
    /**
     *
     */
    async onFilterUsers(query) {
      this.filteredUsers = [];
      this.getUsers.map((user) => {
        if (
          (user.firstname && user.firstname.toLowerCase().indexOf(query) >= 0) ||
          (user.lastname && user.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredUsers.push(user);
        }
      });
    },
    /**
     *
     */
    async onSelectUser(user) {
      // console.log(user);
      // const { data } = user;
      // this.save(data);
    },

    /**
     *
     */
    // async save(student) {
    //   if (student.number === '_new') {
    //     await this.createStudent(student);
    //   } else {
    //     await this.updateStudent(student);
    //   }
    // },
  },
  async mounted() {
    await this.fetchUsers();

    if (this.studentNumber) {
      this.number = this.studentNumber;
      await this.fetchStudentByNumber(this.number);
    } else {
      this.initStudent();
      this.number = '_new';
    }

    this.filteredUsers = this.getUsers;
  },
};
</script>

<style scoped lang="scss"></style>
